import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"
import { Bar } from "react-chartjs-2"

const dataColors = [
  "#f9844a",
  "#f9c74f",
  "#90be6d",
  "#43aa8b",
  "#f94144",
  "#f8961e",
  "#4d908e",
  "#577590",
  "#277da1",
  "#f3722c",
]

const options = {
  title: {
    text: "Total Cost",
    fontSize: 16,
    display: true,
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Liability",
          fontSize: 16,
        },
        ticks: {
          fontSize: 16,
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Amount",
          fontSize: 16,
        },
        ticks: {
          callback: function (value, index, values) {
            if (value > 1000) {
              return "$" + value.toLocaleString()
            }
            if (value % 1 === 0) {
              return "$" + value
            } else {
              return "$" + value.toFixed(2)
            }
          },
          fontSize: 16,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return "$" + parseFloat(tooltipItem.value).toFixed(2)
      },
    },
  },
}

function calculate(amount, years, interestRate) {
  var numPayments = years * 12
  var monthlyInterest = interestRate / 12
  var payment =
    amount *
    ((monthlyInterest * Math.pow(1 + monthlyInterest, numPayments)) /
      (Math.pow(1 + monthlyInterest, numPayments) - 1))
  var totalInterest = 0
  for (var i = 0; i < years; i++) {
    for (var j = 0; j < 12; j++) {
      var interest = amount * (1 + monthlyInterest) - amount
      totalInterest += interest
      amount += interest
      amount = amount - payment
    }
  }
  return { monthlyPayment: payment, totalInterest: totalInterest }
}

const useStyles = makeStyles(theme => ({
  inputs: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    marginBottom: theme.spacing(4),
  },
}))

export default function CompareDebts(props) {
  const classes = useStyles()
  const [amount, setAmount] = useState(props.defaultAmount)
  const [years, setYears] = useState(props.defaultYears)
  const [yearsHasError, setYearsHasError] = useState(false)
  const [amountHasError, setAmountHasError] = useState(false)

  const handleInput = function (val, valSetter, errSetter) {
    var intVal = parseInt(val)
    if (isNaN(intVal)) {
      errSetter(true)
      valSetter("")
    } else {
      valSetter(intVal)
      errSetter(false)
    }
  }
  let calcDebts = []
  props.debts.forEach(function (debt) {
    let { monthlyPayment, totalInterest } = calculate(
      amount,
      years,
      debt.interestRate
    )
    calcDebts.push({
      label: debt.label,
      interestPaid: totalInterest,
      totalPaid: amount + totalInterest,
    })
  })
  const localeStringOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  const chartData = {
    labels: calcDebts.map(debt => debt.label),
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "#f94144",
        borderColor: "#277da1",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: calcDebts.map(debt => debt.totalPaid),
      },
    ],
  }

  return (
    <React.Fragment>
      <TextField
        label={"Starting Amount"}
        type="number"
        id="standard-start-adornment"
        className={classes.inputs}
        value={amount}
        error={amountHasError}
        onInput={e => handleInput(e.target.value, setAmount, setAmountHasError)}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <TextField
        label="Years to Pay Off"
        type="number"
        id="standard-start-adornment"
        className={classes.inputs}
        error={yearsHasError}
        value={years}
        onInput={e => handleInput(e.target.value, setYears, setYearsHasError)}
        InputProps={{
          endAdornment: <InputAdornment position="end">Years</InputAdornment>,
        }}
      />
      <Bar data={chartData} width={100} height={50} options={options} />
    </React.Fragment>
  )
}
