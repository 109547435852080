import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"

function calculate(amount, years, interestRate) {
  var numPayments = years * 12
  var monthlyInterest = interestRate / 12
  var payment =
    amount *
    ((monthlyInterest * Math.pow(1 + monthlyInterest, numPayments)) /
      (Math.pow(1 + monthlyInterest, numPayments) - 1))
  var totalInterest = 0
  for (var i = 0; i < years; i++) {
    for (var j = 0; j < 12; j++) {
      var interest = amount * (1 + monthlyInterest) - amount
      totalInterest += interest
      amount += interest
      amount = amount - payment
    }
  }
  return { monthlyPayment: payment, totalInterest: totalInterest }
}

function calculateCredit(amount, months, interestRate) {
  var numPayments = months
  var monthlyInterest = interestRate / 12
  var payment =
    amount *
    ((monthlyInterest * Math.pow(1 + monthlyInterest, numPayments)) /
      (Math.pow(1 + monthlyInterest, numPayments) - 1))
  var totalInterest = 0
  for (var j = 0; j < numPayments; j++) {
    var interest = amount * (1 + monthlyInterest) - amount
    totalInterest += interest
    amount += interest
    amount = amount - payment
  }
  return { monthlyPayment: payment, totalInterest: totalInterest }
}

const useStyles = makeStyles(theme => ({
  inputs: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    marginBottom: theme.spacing(4),
  },
}))

export default function DebtCost(props) {
  const classes = useStyles()
  const [amount, setAmount] = useState(props.defaultAmount)
  const [years, setYears] = useState(props.defaultYears)
  const [months, setMonths] = useState(props.defaultMonths)
  const [interestRate, setInterestRate] = useState(props.defaultInterestRate)
  const [yearsHasError, setYearsHasError] = useState(false)
  const [monthsHasError, setMonthsHasError] = useState(false)
  const [amountHasError, setAmountHasError] = useState(false)
  const [interestRateHasError, setInterestRateHasError] = useState(false)
  const [displayInterestRate, setDisplayInterestRate] = useState(
    (props.defaultInterestRate * 100).toFixed(2)
  )
  const handleInput = function (val, valSetter, errSetter) {
    var intVal = parseInt(val)
    if (isNaN(intVal)) {
      errSetter(true)
      valSetter("")
    } else {
      valSetter(intVal)
      errSetter(false)
    }
  }
  const handleInterestInput = function (val) {
    setDisplayInterestRate(val)
    if (val === "") {
      setInterestRate(0)
      setInterestRateHasError(false)
    }
    var floatVal = parseFloat(val)
    if (
      isNaN(floatVal) ||
      floatVal > props.upperRateBound ||
      floatVal < props.lowerRateBound
    ) {
      setInterestRateHasError(true)
    } else {
      setInterestRate((floatVal / 100).toFixed(3))
      setInterestRateHasError(false)
    }
  }
  let { monthlyPayment, totalInterest } = props.creditCard
    ? calculateCredit(amount, months, interestRate)
    : calculate(amount, years, interestRate)
  const localeStringOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  return (
    <React.Fragment>
      <TextField
        label={props.creditCard ? "Credit Card Balance" : "Amount"}
        type="number"
        id="standard-start-adornment"
        className={classes.inputs}
        value={amount}
        error={amountHasError}
        onInput={e => handleInput(e.target.value, setAmount, setAmountHasError)}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      {props.creditCard ? (
        <TextField
          label="Desired Months to Pay Off"
          type="number"
          id="standard-start-adornment"
          className={classes.inputs}
          error={monthsHasError}
          value={months}
          onInput={e =>
            handleInput(e.target.value, setMonths, setMonthsHasError)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">Months</InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          label="Loan Length in Years"
          type="number"
          id="standard-start-adornment"
          className={classes.inputs}
          error={yearsHasError}
          value={years}
          onInput={e => handleInput(e.target.value, setYears, setYearsHasError)}
          InputProps={{
            endAdornment: <InputAdornment position="end">Years</InputAdornment>,
          }}
        />
      )}
      <TextField
        label={
          props.creditCard
            ? "Credit Card's Interest Rate"
            : "Interest Rate of Loan"
        }
        type="number"
        id="standard-start-adornment"
        className={classes.inputs}
        error={interestRateHasError}
        helperText={
          "Enter a value from " +
          props.lowerRateBound +
          " to " +
          props.upperRateBound
        }
        value={displayInterestRate}
        onInput={e => handleInterestInput(e.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />

      <h3>Results</h3>
      <table className={classes.table}>
        <tr>
          <td>
            <strong>Monthly Payment</strong>
          </td>
          <td style={{ color: "#277da1" }}>
            <strong>
              {"$" +
                (monthlyPayment
                  ? monthlyPayment.toLocaleString("en", localeStringOptions)
                  : 0)}
            </strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Total Paid in Interest</strong>
          </td>
          <td style={{ color: "#277da1" }}>
            <strong>
              {"$" +
                (totalInterest
                  ? totalInterest.toLocaleString("en", localeStringOptions)
                  : 0)}
            </strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Total Cost to You</strong>
          </td>
          <td style={{ color: "#277da1" }}>
            <strong>
              {"$" +
                (totalInterest
                  ? (amount + totalInterest).toLocaleString(
                      "en",
                      localeStringOptions
                    )
                  : 0)}
            </strong>
          </td>
        </tr>
      </table>
    </React.Fragment>
  )
}
