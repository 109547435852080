import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"
import { Line } from "react-chartjs-2"

const dataColors = [
  "#f9844a",
  "#f9c74f",
  "#90be6d",
  "#43aa8b",
  "#f94144",
  "#f8961e",
  "#4d908e",
  "#577590",
  "#277da1",
  "#f3722c",
]

const options = {
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Year",
          fontSize: 16,
        },
        ticks: {
          fontSize: 16,
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Amount",
          fontSize: 16,
        },
        ticks: {
          callback: function (value, index, values) {
            if (value > 1000) {
              return "$" + value.toLocaleString()
            }
            if (value % 1 === 0) {
              return "$" + value
            } else {
              return "$" + value.toFixed(2)
            }
          },
          fontSize: 16,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return "$" + parseFloat(tooltipItem.value).toFixed(2)
      },
    },
  },
}

function compoundInterest(starting, years, rate, n) {
  var runningSums = []
  runningSums.push(starting)
  var amount = starting
  for (var i = 0; i < years; i++) {
    for (var j = 0; j < n; j++) {
      amount = amount * (1 + rate / n)
    }
    runningSums.push(amount)
  }
  return runningSums
}

function buildData(numYears, amount, investments) {
  var data = {}
  data.labels = []
  data.datasets = []
  for (var i = 0; i < numYears + 1; i++) {
    data.labels.push(i)
  }
  for (var i = 0; i < investments.length; i++) {
    data.datasets.push(
      buildDataSet(
        i,
        numYears,
        amount,
        investments[i].rate,
        investments[i].name
      )
    )
  }
  return data
}

function buildDataSet(index, numYears, amount, interestRate, name) {
  const color = dataColors[index]
  return {
    label: name,
    fill: false,
    lineTension: 0.1,
    backgroundColor: color,
    borderColor: color,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: color,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: compoundInterest(amount, numYears, interestRate, 12),
  }
}

const useStyles = makeStyles(theme => ({
  inputs: {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
}))

export default function CompoundInterest(props) {
  const classes = useStyles()
  const [amount, setAmount] = useState(1000)
  const [years, setYears] = useState(props.defaultYears)
  const [yearsHasError, setYearsHasError] = useState(false)
  const [amountHasError, setAmountHasError] = useState(false)
  const handleInput = function (val, valSetter, errSetter) {
    var intVal = parseInt(val)
    if (isNaN(intVal)) {
      errSetter(true)
      valSetter("")
    } else {
      valSetter(intVal)
      errSetter(false)
    }
  }

  return (
    <React.Fragment>
      <TextField
        label="Amount"
        id="standard-start-adornment"
        className={classes.inputs}
        value={amount}
        error={amountHasError}
        onInput={e => handleInput(e.target.value, setAmount, setAmountHasError)}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <TextField
        label="Length of Time"
        id="standard-start-adornment"
        className={classes.inputs}
        error={yearsHasError}
        value={years}
        onInput={e => handleInput(e.target.value, setYears, setYearsHasError)}
        InputProps={{
          endAdornment: <InputAdornment position="end">Years</InputAdornment>,
        }}
      />
      <Line
        data={buildData(years, amount, props.investments)}
        options={options}
      />
    </React.Fragment>
  )
}
